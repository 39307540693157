<template>
    <div class="layout-inline-menu" :class="{'layout-inline-menu-active': active}">
    <a class="layout-inline-menu-action flex flex-row align-items-center" :class="menuMode === 'horizontal' ? 'p-3 lg:p-1 py-3' : 'p-3'" :style="{'min-width': menuMode === 'horizontal' ? '165px' : 0} " @click="onClick($event)">
        <img src="layout/images/avatar/enat.png" alt="avatar" style="width: 32px; height: 32px;">
        <span class="flex flex-column" :class="{'ml-2': !isRTL, 'mr-2': isRTL}">
            <span class="font-bold">{{ username }}</span>
            <small>{{ name }}</small>
        </span>
        <i class="layout-inline-menu-icon pi pi-angle-down" :class="{'ml-auto': !isRTL, 'mr-auto': isRTL}"></i>
    </a>
    <span class="layout-inline-menu-tooltip p-tooltip">
		<span class="layout-inline-menu-tooltip-arrow p-tooltip-arrow"></span>
		<span class="layout-inline-menu-tooltip-text p-tooltip-text">Amy Elsner</span>
	</span>
    
    <transition :name="menuMode === 'horizontal' && !isMobile() ? 'layout-inline-menu-action-panel-horizontal' : 'layout-inline-menu-action-panel'">
        <ul v-show="active" :class="['layout-inline-menu-action-panel', { 'layout-inline-menu-action-panel-horizontal' : menuMode === 'horizontal' && !isMobile()}]">
            <li class="layout-inline-menu-action-item">
                <router-link to="/userprofile">
                <a class="flex flex-row align-items-center">
                    <i class="pi pi-user pi-fw"></i>
                    <span>Benutzerprofil</span>
                </a>
                </router-link>
                <span class="layout-inline-menu-tooltip p-tooltip">
					<span class="layout-inline-menu-tooltip-arrow p-tooltip-arrow"></span>
					<span class="layout-inline-menu-tooltip-text p-tooltip-text">Benutzerprofil</span>
				</span>
            </li>
            <li class="layout-inline-menu-action-item">
                <router-link to="/logout">
                <a class="flex flex-row align-items-center">
                    <i class="pi pi-power-off pi-fw"></i>
                    <span>Logout</span>
                </a>
                </router-link>
                <span class="layout-inline-menu-tooltip p-tooltip">
					<span class="layout-inline-menu-tooltip-arrow p-tooltip-arrow"></span>
					<span class="layout-inline-menu-tooltip-text p-tooltip-text">Logout</span>
				</span>
            </li>
    </ul>
    </transition>
</div>

</template>

<script>
import { mapGetters } from "vuex";
	export default {
        name: "AppInlineMenu",
        props: {
            active: {
                type: Boolean,
                default: false
            },
            menuMode: String,
            inlineMenuKey: String
        },
		methods: {
			onClick(event) {
                this.$emit('change-inlinemenu', !this.active, this.inlineMenuKey);
				event.preventDefault();
			},
            isMobile() {
                return window.innerWidth <= 991;
            },
            logout() {
                this.$store.dispatch('auth/logout');
            }
		},
        computed: {
            ...mapGetters({
                isLoggedIn: 'auth/isLoggedIn',
                loginStatus: 'auth/loginStatus',
                getUser: 'auth/getUser',
                viewMenu: 'viewMenu',
            }),
            isRTL() {
				return this.$appState.RTL;
			},
            username() {
                if (this.getUser && this.getUser.username) return this.getUser.username;
                else return '';
            },
            name() {
                if (this.getUser && this.getUser.name) return this.getUser.name;
                else return '';
            }
        }
	}
</script>

<style scoped>

</style>
