<template>
    <div :class="'layout-breadcrumb-container flex justify-content-between align-items-center shadow-1' + isEditMode">
        <Breadcrumb :home="home" :model="items" class="layout-breadcrumb pl-4 py-2"></Breadcrumb>
        <span v-if="editMode"><strong>! BEARBEITUNGS-MODUS !</strong></span>

        <div class="layout-breadcrumb-buttons flex align-items-center pr-2">
            <Button icon="pi pi-cloud-upload" class="p-button-rounded p-button-text p-button-plain mr-1" @click="getSocket"></Button>
            <Button icon="pi pi-bookmark" class="p-button-rounded p-button-text p-button-plain mr-1"></Button>
            <Badge :value="connectionCount" class="p-badge-plain mr-1"></Badge>
            <Button icon="pi pi-power-off" :class="'p-button-rounded p-button-text mr-0' + systemStatusIcon"></Button>
            <Button icon="pi pi-sync" :class="'p-button-rounded p-button-text mr-0' + isLoadingIcon" v-tooltip.left="loadingCount" @click="getServerData"></Button>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            home: { icon: 'pi pi-home', to: '/' },
            items: [],
            editMode: false,
        };
    },
    computed: {
        ...mapGetters([
            'getMapValue',
            'isLoading',
            'getSystemStatus',
            'getSystemStatusBool',
            'connectionCount',
            'getActiveConnections',
            'getPageData',
        ]),
        isLoadingIcon: function () {
            if (this.isLoading > 0) return ' pi-spin';
            else return ' p-button-plain';
        },
        systemStatusIcon: function () {
            if (this.getSystemStatusBool) return '';
            else return ' p-button-danger';
        },
        loadingCount: function () {
            return this.isLoading.toString();
        },
        isEditMode: function () {
            if (this.editMode === true) {
                return ' bg-magenta';
            } else {
                return '';
            }
        },
    },
    watch: {
        $route() {
            this.watchRouter();
        },
        '$store.getters.getPageData': function () {
            this.watchRouter();
        },
    },
    created() {
        this.watchRouter();
    },
    methods: {
        watchRouter() {
            if (this.$router.currentRoute.value.meta.breadcrumb) {
                this.items = [];
                const bc = this.$router.currentRoute.value.meta.breadcrumb[0];
                for (let pro in bc) {
                    this.items.push({ label: bc[pro] });
                }
                if (bc.label === 'Bedienung') {
                    // this.items.push({ label: this.$route.params.page });
                    this.items.push({ label: this.getPageData.label });
                }
                if (this.getPageData.editMode === true) {
                    this.editMode = true;
                } else {
                    this.editMode = false;
                }
                console.log("APP_Breadcrumb Top:", bc, "Route:", this.$route);
                document.title = 'ENAT Klimacomputer > ' + bc.label;
            }
        },
        getServerData() {
            // SocketioService.reloadAll();
            // this.$store.dispatch("socketIoLoadClientData", null);
            // this.$store.dispatch("socketIoLoadConfigIo", null);
            // this.$store.dispatch("socketIoLoadStatusIo", null);
            // this.$store.dispatch("socketIoLoadMap");
        },
        getSocket() {
            // this.$store.dispatch("socketIoLoadMap");
        },
    },
};
</script>

<style scoped>
</style>
