<template>
    <div class="card overview-box flex pt-3 pb-2 mb-1 solid-surface-text-color" :class="getSystemStatusBool ? 'fg-lightGray bg-darkRed' : 'fg-lightYellow bg-lightMagenta'">
        <div class="mr-1"><h4>{{ getSystemStatusBool ? '🌥' : '⚠' }}</h4></div>
        <div class="flex flex-column">
            <span id="sideMenuSystemStatusCustomerName"><strong>{{ clientName }}</strong></span>
            <span id="sideMenuSystemStatusCustomerDesc"><small>{{ clientDesc }}</small></span>
            <span id="sideMenuSystemStatusDateTime" class="mt-0"><small>{{ getSystemStatusBool ? '🕐' : '⚠' }} {{ currentTimeFormatted }}</small></span>
            <!-- <Chip :label="dateTime" icon="pi pi-clock" class="mb-1 mt-1 text-sm" /> -->
        </div>
    </div>
    <AppSubmenu
        class="layout-menu"
        :items="model"
        :menuMode="menuMode"
        :menuActive="active"
        :isSlimOrHorItemClick="isSlimOrHorItemClick"
        :root="true"
        :parentMenuItemActive="true"
        :mobileMenuActive="mobileMenuActive"
        @menuitem-click="onMenuItemClick"
        @root-menuitem-click="onRootMenuItemClick"
    />
</template>

<script>
import AppSubmenu from './AppSubmenu';
import { mapGetters } from "vuex";

export default {
    name: 'AppMenu',
    emits: ['menu-click', 'menuitem-click', 'root-menuitem-click'],
    props: {
        model: Array,
        menuMode: String,
        active: Boolean,
        mobileMenuActive: Boolean,
        isSlimOrHorItemClick: Boolean,
    },
    components: {
        AppSubmenu: AppSubmenu,
    },
    computed: {
        ...mapGetters(["currentTimeFormatted", "clientName", "clientDesc", "getSystemStatusBool"]),
    },
    methods: {
        onMenuClick(event) {
            this.$emit('menu-click', event);
        },
        onMenuItemClick(event) {
            this.$emit('menuitem-click', event);
        },
        onRootMenuItemClick(event) {
            this.$emit('root-menuitem-click', event);
        },
    },
};
</script>

<style scoped>
</style>